
import BarChartIcon from '@material-ui/icons/BarChart';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';

export default [
  {
    items: [
      {
        title: 'Usuarios',
        href: '/user',
        icon: BarChartIcon,
        items: [
          {
            title: 'Usuarios',
            href: '/users'
          }
        ]
      }
    ]
  },
];
