/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';

const routes=[
  [
    {
      path: '/',
      exact: true,
      component: () => <Redirect to="/user" />
    },
    {
      path: '/errors',
      component: ErrorLayout,
      routes: [
        {
          path: '/errors/error-401',
          exact: true,
          component: lazy(() => import('src/views/Error401'))
        },
        {
          path: '/errors/error-404',
          exact: true,
          component: lazy(() => import('src/views/Error404'))
        },
        {
          path: '/errors/error-500',
          exact: true,
          component: lazy(() => import('src/views/Error500'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      route: '*',
      component: DashboardLayout,
      routes: [
        {
          path: '/user',
          exact: true,
          component: lazy(() => import('src/views/InfoUser'))
        },
        {
          path: '/shirley',
          exact: true,
          component: lazy(() => import('src/views/InfoUser'))
        },
        {
          path: '/edit-user',
          exact: true,
          component: lazy(() => import('src/views/EditUserClient'))
        },
        {
          path: '/video',
          exact: true,
          component: lazy(() => import('src/views/Videos'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }

      ]
    }
  ],
  [
    {
      path: '/',
      exact: true,
      component: () => <Redirect to="/products" />
    },
    {
      path: '/errors',
      component: ErrorLayout,
      routes: [
        {
          path: '/errors/error-401',
          exact: true,
          component: lazy(() => import('src/views/Error401'))
        },
        {
          path: '/errors/error-404',
          exact: true,
          component: lazy(() => import('src/views/Error404'))
        },
        {
          path: '/errors/error-500',
          exact: true,
          component: lazy(() => import('src/views/Error500'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      route: '*',
      component: DashboardLayout,
      routes: [
        {
          path: '/user2',
          exact: true,
          component: () => <Redirect to="/user" />
        },
        {
          path: '/concurso',
          exact: true,
          component: lazy(() => import('src/views/Concurso'))
        },
        {
          path: '/products',
          exact: true,
          component: lazy(() => import('src/views/ListProduct'))
        },
        {
          path: '/admin',
          exact: true,
          component: lazy(() => import('src/views/ListAdmin'))
        },
        {
          path: '/sale',
          exact: true,
          component: lazy(() => import('src/views/ListSale'))
        },
        {
          path: '/products/:categoryid',
          exact: true,
          component: lazy(() => import('src/views/ListProductById'))
        },
        {
          path: '/category',
          exact: true,
          component: lazy(() => import('src/views/ListCategory'))
        },
        {
          //Vista de todas las compañias agregadas
          path: '/dashboard',
          exact: true,
          component: lazy(() => import('src/views/DashboardDefault'))
        },
        {
          path: '/ventas',
          exact: true,
          component: lazy(() => import('src/views/ListVentas'))
        },
        {
          path: '/user',
          exact: true,
          component: lazy(() => import('src/views/User'))
        },
        {
          path: '/ver-venta/:cestaid',
          exact: true,
          component: lazy(() => import('src/views/InfoVenta'))
        },
        {
          path: '/sensors',
          exact: true,
          component: lazy(() => import('src/views/ListSensor'))
        },
        {
          path: '/mersh-sensor/:pruebaid',
          exact: true,
          component: lazy(() => import('src/views/MershSensor'))
        },
        {
          path: '/entry/:companyid',
          exact: true,
          component: lazy(() => import('src/views/ListEntry'))
        },
        {
          path: '/ensayo/:entryid',
          exact: true,
          component: lazy(() => import('src/views/ListEnsayo'))
        },
        {
          path: '/prueba/:ensayoid',
          exact: true,
          component: lazy(() => import('src/views/ListPrueba'))
        },
        {
          path: '/users',
          exact: true,
          component: lazy(() => import('src/views/ListUsers'))
        },
        {
          path: '/search',
          exact: true,
          component: lazy(() => import('src/views/SearchUsers'))
        },
        
        {
          path: '/add-blog',
          exact: true,
          component: lazy(() => import('src/views/AddBlog'))
        },
        {
          path: '/add-entry/:companyid',
          exact: true,
          component: lazy(() => import('src/views/AddEntry'))
        },
        {
          path: '/add-ensayo/:entryid',
          exact: true,
          component: lazy(() => import('src/views/AddEnsayo'))
        },
        {
          path: '/add-prueba/:ensayoid',
          exact: true,
          component: lazy(() => import('src/views/AddPrueba'))
        },
        {
          path: '/add-user',
          exact: true,
          component: lazy(() => import('src/views/AddUser'))
        },
        {
          path: '/edit-product/:productid',
          exact: true,
          component: lazy(() => import('src/views/EditProduct'))
        },
        {
          path: '/add-product',
          exact: true,
          component: lazy(() => import('src/views/AddProduct'))
        },
        {
          path: '/week',
          exact: true,
          component: lazy(() => import('src/views/ListWeek'))
        },
        {
          path: '/ventas/:weekid',
          exact: true,
          component: lazy(() => import('src/views/ListVentasById'))
        },
        {
          path: '/week-admin/:adminid',
          exact: true,
          component: lazy(() => import('src/views/ListWeekByAdmin'))
        },
        {
          path: '/edit-entry/:entryid',
          exact: true,
          component: lazy(() => import('src/views/EditEntry'))
        },
        {
          path: '/edit-ensayo/:ensayoid',
          exact: true,
          component: lazy(() => import('src/views/EditEnsayo'))
        },
        {
          path: '/edit-prueba/:pruebaid',
          exact: true,
          component: lazy(() => import('src/views/EditPrueba'))
        },
        {
          path: '/edit-client/:userid',
          exact: true,
          component: lazy(() => import('src/views/EditUser'))
        },
        {
          path: '/prueba/data/:pruebaid',
          exact: true,
          component: lazy(() => import('src/views/Device'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    }
  ],
  [
    {
      path: '/',
      component: AuthLayout,
      routes: [
        {
          path: '/auth/login',
          exact: true,
          component: lazy(() => import('src/views/Login'))
        },
        {
          path: '/auth/register',
          exact: true,
          component: lazy(() => import('src/views/Register'))
        },
        {
          component: () => <Redirect to="/auth/login" />
        }
      ]
    }
  ]
]
export default routes
