
import BarChartIcon from '@material-ui/icons/BarChart';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';

export default [
  {
    items: [
      {
        title: 'Productos',
        href: '/Productos',
        icon: HomeIcon,
        items: [
          {
            title: 'Productos',
            href: '/products'
          },
          {
            title: 'Promociones',
            href: '/sale'
          },
        
        ]
      },
      {
        title: 'Ventas',
        href: '/Ventas',
        icon: HomeIcon,
        items: [
          {
            title: 'Ventas',
            href: '/ventas'
          },          
          {
            title: 'Week',
            href: '/week'
          },
        ]
      },
      {
        title: 'Usuarios',
        href: '/admin',
        icon: HomeIcon,
        items: [
          {
            title: 'Usuarios',
            href: '/admin'
          },
        ]
      },
    ]
  },
];
